<template>
  <div>
    <v-card flat>
      <AppLoadingSpinner v-model="isLoading" />
      <v-card-title>
        {{ displayTitle }}
        <v-spacer></v-spacer>
        <AppTooltipBtn
          btn-class="mr-4"
          color="primary"
          icon="mdi-printer"
          tooltip="打印"
          @click="printReport"
        />
        <AppMenuBtn
          btn-class="mr-4"
          color="primary"
          icon="mdi-download"
          tooltip="下载"
        >
          <v-list>
            <v-list-item @click="displayHtmlAsImage">
              <v-icon class="mr-2">mdi-file-image</v-icon>
              下载为图片
            </v-list-item>
            <v-list-item @click="downloadAsPdf">
              <v-icon class="mr-2">mdi-file-pdf</v-icon>
              下载为PDF
            </v-list-item>
          </v-list>
        </AppMenuBtn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height:70vh;overflow:auto;">
        <div id="reportDiv" v-html="errorMsg || answerDetailsHtml"></div>
      </v-card-text>
      <v-divider></v-divider>
    </v-card>
    <AppDialog
      action-divider
      v-model="isShowImageDialog"
      overflow-height="300px"
      cancel-text="关闭"
    >
      <img :src="imageSrc" style="max-width:100%;" />
      <template #action-ex>
        <span class="ml-4">长按或右击保存图片</span>
      </template>
    </AppDialog>
    <AppMessageBox v-model="dialogErrorMsg" title="发生错误" />
  </div>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppDialog from "@/components/AppDialog";
import AppTooltipBtn from "@/components/AppTooltipBtn";
import AppMenuBtn from "@/components/AppMenuBtn";
import AppMessageBox from "@/components/AppMessageBox";
import printJS from "print-js";
import {
  fetchTestAnswerDetailsHtml,
  saveAnswerDetailsToPdf
} from "@/api/report";
import { downloadFile } from "@/utils/download";
import { getReportImageBase64 } from "@/utils/reportCanvas";

export default {
  components: {
    AppLoadingSpinner,
    AppDialog,
    AppTooltipBtn,
    AppMenuBtn,
    AppMessageBox
  },

  props: {
    caseGuid: {
      type: String
    }
  },

  data() {
    return {
      isLoading: false,
      isDialogLoading: false,
      title: "",
      answerDetailsHtml: "",
      imageSrc: "",
      errorMsg: "",
      dialogErrorMsg: "",
      // dialog
      isShowImageDialog: false
    };
  },

  watch: {
    async caseGuid(newGuid) {
      await this.generateAnswerDetailsHtml(newGuid);
    }
  },

  computed: {
    displayTitle() {
      return (
        this.title || (this.errorMsg ? "无法生成答题详情" : "答题详情生成中...")
      );
    }
  },

  methods: {
    resetData() {
      this.answerDetailsHtml = "";
      this.errorMsg = "";
    },
    async generateAnswerDetailsHtml(guid) {
      if (guid && guid.length) {
        try {
          this.isLoading = true;
          let ansDetails = await fetchTestAnswerDetailsHtml(guid);
          this.title = ansDetails.title;
          this.answerDetailsHtml = ansDetails.html;
        } catch (err) {
          this.errorMsg = err.message;
        }
        this.isLoading = false;
      } else {
        // guid 和 objList 为空，表示报告的 dialog 被关闭
        this.resetData();
      }
    },
    // 生成图片
    async displayHtmlAsImage() {
      try {
        this.isLoading = true;
        let reportDom = document.getElementById("reportDiv");
        if (reportDom) {
          this.imageSrc = await getReportImageBase64(reportDom, 800);
          this.isShowImageDialog = true;
        }
      } catch (err) {
        this.dialogErrorMsg = err.message;
      }
      this.isLoading = false;
    },
    // 下载 pdf
    async downloadAsPdf() {
      try {
        this.isLoading = true;
        let downloadPath = await saveAnswerDetailsToPdf(this.caseGuid);
        downloadFile(downloadPath, `${this.title}.pdf`);
      } catch (err) {
        this.dialogErrorMsg = err.message;
      }
      this.isLoading = false;
    },
    // 打印
    printReport() {
      printJS({
        printable: "reportDiv",
        type: "html",
        showModal: true
      });
    }
  },

  created() {
    this.generateAnswerDetailsHtml(this.caseGuid);
  }
};
</script>
