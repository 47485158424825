<template>
  <v-container>
    <AppLoadingSpinner v-model="isLoading" />
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            案例管理
            <AppTooltipBtn
              btn-class="ml-5 my-2"
              icon="mdi-help-circle-outline"
              tooltip="团体报告帮助"
              @click="isShowHelpDialog = true"
            />
            <AppTooltipBtn
              rounded
              color="primary"
              icon="mdi-file-chart-outline"
              label="团体报告"
              tooltip="生成团体报告，只能选择同一个量表进行"
              @click="showGroupReport"
            />
            <AppMenuBtn
              rounded
              btn-class="ml-5 my-2"
              color="primary"
              icon="mdi-folder-zip-outline"
              label="批量下载报告"
              tooltip="把选中的案例，批量导出报告并下载为zip压缩包"
            >
              <v-list>
                <v-list-item
                  v-for="mode in reportModeList"
                  :key="mode.value"
                  @click="bulkDownloadAsZip(mode.value)"
                >
                  {{ mode.text }}
                </v-list-item>
              </v-list>
            </AppMenuBtn>
            <AppMenuBtn
              rounded
              btn-class="ml-5 my-2"
              color="primary"
              icon="mdi-file-download-outline"
              label="合并报告"
              tooltip="把选中的案例，合并为同一个pdf报告并下载"
            >
              <v-list>
                <v-list-item
                  v-for="mode in reportModeList"
                  :key="mode.value"
                  @click="bulkDownloadAsPdf(mode.value)"
                >
                  {{ mode.text }}
                </v-list-item>
              </v-list>
            </AppMenuBtn>
            <AppTooltipBtn
              rounded
              btn-class="ml-5 my-2"
              color="primary"
              icon="mdi-bookmark-plus-outline"
              label="批量标记"
              tooltip="批量设置选中行的标记，也可点击每行的标记单元格单独设置标记"
              @click="bulkSetCaseMark"
            />
            <AppTooltipBtn
              rounded
              btn-class="ml-5 my-2"
              color="primary"
              icon="mdi-file-excel-outline"
              label="导出Excel"
              tooltip="根据选中案例的分值导出Excel，案例必须为同一张量表"
              @click="extractCasesToExcel"
            />
            <AppTooltipBtn
              v-if="isAdminRole"
              rounded
              btn-class="ml-5 my-2"
              color="error"
              icon="mdi-delete"
              label="批量删除"
              tooltip="删除所有选中的案例"
              @click="deleteCases(true)"
            />
            <v-spacer></v-spacer>
            <AppTooltipBtn
              text
              rounded
              btn-class="ml-5 my-2"
              color="green"
              icon="mdi-tune"
              :label="isShowFilterPanel ? '关闭案例筛选' : '打开案例筛选'"
              tooltip="打开或关闭筛选面板"
              @click="isShowFilterPanel = !isShowFilterPanel"
            />
          </v-card-title>
          <v-slide-y-transition>
            <v-card-text v-if="isShowFilterPanel">
              <div class="d-flex flex-wrap mx-2">
                <v-select
                  class="mr-6 filter-item"
                  multiple
                  chips
                  small-chips
                  dense
                  single-line
                  outlined
                  clearable
                  :label="deptColumnAlias"
                  :items="allDeptNamesFromCaseList"
                  v-model="caseFilters.deptNames"
                ></v-select>
                <v-select
                  class="mr-4 filter-item"
                  multiple
                  chips
                  small-chips
                  dense
                  single-line
                  outlined
                  clearable
                  label="量表"
                  :items="allLbNamesFromCaseList"
                  v-model="caseFilters.lbNames"
                ></v-select>
                <v-menu
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="350px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="mr-4 filter-item"
                      v-model="filterDateRangeText"
                      prepend-inner-icon="mdi-calendar-text"
                      label="测量日期"
                      outlined
                      dense
                      single-line
                      clearable
                      v-on="on"
                      v-bind="attrs"
                      @click:clear="caseFilters.dateRange = []"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="caseFilters.dateRange"
                    full-width
                    no-title
                    range
                    show-adjacent-months
                    locale="zh-cn"
                    :day-format="d => d.split('-')[2]"
                  ></v-date-picker>
                </v-menu>
                <v-text-field
                  class="mr-4 filter-item filter-item-person"
                  v-model="personFilterValue"
                  prepend-inner-icon="mdi-magnify"
                  :label="`搜索${personFilterFieldSelected}`"
                  outlined
                  dense
                  single-line
                  clearable
                >
                  <template v-slot:prepend-inner>
                    <v-select
                      class="filter-item-inner-select"
                      dense
                      single-line
                      filled
                      hide-details
                      :items="filterPersonFieldList"
                      v-model="personFilterFieldSelected"
                    ></v-select>
                  </template>
                </v-text-field>
              </div>
              <AppTooltipBtn
                class="ml-6"
                color="primary"
                label="筛选"
                icon="mdi-filter"
                small
                small-icon
                @click="filterCaseList"
              />
              <AppTooltipBtn
                class="ml-6"
                text
                color="primary"
                label="重置"
                icon="mdi-refresh"
                small
                @click="resetCaseFilters"
              />
            </v-card-text>
          </v-slide-y-transition>
          <v-data-table
            class="mx-4"
            v-model="caseListSelected"
            :headers="caseListHeaders"
            :items="caseList"
            item-key="guid"
            :sort-by="caseSortBy"
            :sort-desc="caseSortDesc"
            show-select
            :single-select="false"
            :loading="isCaseListLoading"
            loading-text="正在读取案例列表，请稍候..."
            no-data-text="未找到任何案例"
            no-results-text="未找到任何匹配案例"
            :footer-props="{
              showFirstLastPage: true,
              itemsPerPageAllText: '所有',
              itemsPerPageText: '每页案例数：',
              itemsPerPageOptions: [5, 10, 20, 50, 100, -1]
            }"
            @toggle-select-all="caseToggleSelectAll"
          >
            <template
              v-slot:[`footer.page-text`]="{ pageStart, pageStop, itemsLength }"
            >
              {{
                `共 ${itemsLength} 个案例，本页显示案例 ${pageStart}-${pageStop} ${selectedCaseCountText}`
              }}
            </template>
            <template v-slot:[`item.testPerson.testeeNum`]="{ item }">
              {{
                buildTesteeNumPrefix(item.testPerson.testeeNumPrefix) +
                  item.testPerson.testeeNum
              }}
            </template>
            <template v-slot:[`item.caseMark`]="{ item }">
              <v-edit-dialog
                :return-value.sync="item.caseMark"
                large
                save-text="保存"
                cancel-text="取消"
                @save="singleSetCaseMark(item.guid, item.caseMark)"
              >
                <div>{{ item.caseMark }}</div>
                <template v-slot:input>
                  <div class="mt-4 text-h6">更新标记</div>
                  <v-text-field
                    single-line
                    dense
                    autofocus
                    label="案例标记"
                    v-model="item.caseMark"
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:[`item.alertList`]="{ value }">
              <v-tooltip bottom v-for="(alert, idx) in value" :key="idx">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="!!alert.displayName"
                    :color="`${alert.colorHex}`"
                    v-on="on"
                    v-bind="attrs"
                  >
                    mdi-record
                  </v-icon>
                </template>
                <span>{{ alert.displayName }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <AppMenuBtn
                btn-class="mr-2"
                color="primary"
                icon="mdi-file-document-outline"
                tooltip="查看案例报告"
              >
                <v-list
                  v-if="mmpiLbIdList.includes(item.lbId)"
                  :key="item.guid"
                >
                  <v-list-item
                    v-for="mode in mmpiReportModeList"
                    :key="mode.value"
                    @click="showAdminReport(item, mode.value)"
                  >
                    {{ mode.text }}
                  </v-list-item>
                </v-list>
                <v-list
                  v-else-if="nuclearLbIdList.includes(item.lbId)"
                  :key="item.guid"
                >
                  <v-list-item
                    v-for="mode in nuclearReportModeList"
                    :key="mode.value"
                    @click="showAdminReport(item, mode.value)"
                  >
                    {{ mode.text }}
                  </v-list-item>
                </v-list>
                <v-list v-else :key="item.guid">
                  <v-list-item
                    v-for="mode in reportModeList"
                    :key="mode.value"
                    @click="showAdminReport(item, mode.value)"
                  >
                    {{ mode.text }}
                  </v-list-item>
                </v-list>
              </AppMenuBtn>
              <AppTooltipBtn
                btn-class="mr-2"
                color="primary"
                icon="mdi-file-table-outline"
                tooltip="查看答题详情"
                @click="showAnswerDetails(item)"
              />
              <AppTooltipBtn
                btn-class="mr-2"
                color="primary"
                icon="mdi-account-edit"
                tooltip="修改案例的个人信息"
                @click="editOneCase(item)"
              />
              <AppTooltipBtn
                v-if="isAdminRole"
                color="red"
                icon="mdi-delete"
                tooltip="删除这个案例"
                @click="deleteCases(false, item)"
              />
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <AppDialog
      v-model="isShowFilterDateRangeDialog"
      size="small"
      title="选择要筛选的日期范围"
      color="primary"
      action-text="确定"
      :show-cancel="false"
      text-class="text-center"
      @confirm="isShowFilterDateRangeDialog = false"
    >
      <v-date-picker
        v-model="caseFilters.dateRange"
        no-title
        range
        show-adjacent-months
        locale="zh-cn"
      ></v-date-picker>
    </AppDialog>
    <AppDialog
      v-model="isShowEditDialog"
      persistent
      size="small"
      title="编辑案例个人信息"
      text-class="px-10"
      color="green"
      action-text="保存"
      :loading="isBtnLoading"
      @confirm="editOneCaseConfirmed"
      @closed="caseEditDialogClosed"
    >
      <PersonInfoEditor
        :user-entity="userEntity"
        :user-guid="userGuid"
        v-model="editingCasePerson"
        :no-update-fields="casePersonNoUpdateFields"
        @update:no-update-fields="casePersonNoUpdateFields = $event"
      />
    </AppDialog>
    <AppDialog
      v-model="isShowDeleteDialog"
      size="small"
      :title="caseDeleteDialogTitle"
      color="red"
      action-text="删除"
      :loading="isBtnLoading"
      @confirm="deleteCasesConfirmed"
      @closed="caseDeleteDialogClosed"
    >
      删除后不可恢复
    </AppDialog>
    <AppDialog
      v-model="isShowCaseMarkDialog"
      size="small"
      title="批量设置案例标记"
      text-class="px-10"
      color="success"
      action-text="确定"
      :loading="isBtnLoading"
      @confirm="bulkSetCaseMarkConfirmed"
      @closed="caseMarkDialogClosed"
    >
      <v-text-field
        label="标记"
        v-model="newCaseMark"
        clearable
        :rules="fieldRules.caseMark"
      ></v-text-field>
    </AppDialog>
    <AppDialog v-model="isShowHelpDialog" title="团体报告帮助">
      <p class="font-weight-bold">点击“团体报告”按钮查看团体报告</p>
      <h4>生成条件：</h4>
      <p>需要选中6个或以上，基于相同量表的测试结果</p>
      <h4>支持生成团体报告的量表：</h4>
      <ul>
        <li>症状自评量表(SCL-90)</li>
        <li>卡特尔16项人格测验（16PF）</li>
      </ul>
    </AppDialog>
    <v-dialog
      v-model="isShowAdminReportDialog"
      max-width="800px"
      style="max-height:100%;"
    >
      <v-card>
        <AdminGroupReportDisplayer
          v-if="reportDisplayerType === 'group'"
          :case-obj-list="actionCaseObjList"
        />
        <AdminReportDisplayer
          v-else-if="reportDisplayerType === 'admin'"
          :case-guid="actionCaseGuid"
          :mode="selectedReportMode"
          :reporter-candidates="reporterCandidateList"
        />
        <AdminReportAnswerDisplayer
          v-else-if="reportDisplayerType === 'answer'"
          :case-guid="actionCaseGuid"
        />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeAdminReportDialog">关闭</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </v-container>
</template>

<script>
import AdminReportDisplayer from "@/components/admin/AdminReportDisplayer";
import AdminGroupReportDisplayer from "@/components/admin/AdminGroupReportDisplayer";
import AdminReportAnswerDisplayer from "@/components/admin/AdminReportAnswerDisplayer";
import PersonInfoEditor from "@/components/PersonInfoEditor";
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppDialog from "@/components/AppDialog";
import AppMessageBox from "@/components/AppMessageBox";
import AppTooltipBtn from "@/components/AppTooltipBtn";
import AppMenuBtn from "@/components/AppMenuBtn";
import { mapGetters } from "vuex";
import _ from "lodash";
import {
  fetchCaseList,
  deleteCases,
  saveCaseMark,
  extractExcelFromCases
} from "@/api/case";
import {
  mmpiLbIdRange,
  nuclearLbIdRange,
  reportMode,
  bulkSaveReportToZip,
  bulkSaveReportToSinglePdf
} from "@/api/report";
import { saveEditedPerson } from "@/api/person";
import { getUserFieldConfig } from "@/api/fieldConfig";
import { fetchReportSignInfo } from "@/api/reportInfo";
import { downloadFile } from "@/utils/download";

export default {
  components: {
    AdminReportDisplayer,
    AdminGroupReportDisplayer,
    AdminReportAnswerDisplayer,
    PersonInfoEditor,
    AppLoadingSpinner,
    AppDialog,
    AppMessageBox,
    AppTooltipBtn,
    AppMenuBtn
  },

  data() {
    return {
      // loading
      isCaseListLoading: false,
      isBtnLoading: false,
      isLoading: false,
      // case list
      caseList: [],
      caseListSelected: [],
      userFieldConfigList: [],
      caseListHeaders: [],
      caseSortBy: [],
      caseSortDesc: [],
      // filters
      isShowFilterPanel: true,
      caseFilters: {
        lbNames: [],
        deptNames: [],
        dateRange: []
      },
      personFilterFieldSelected: "",
      personFilterValue: "",
      // dialogs
      errorMsg: "",
      isShowFilterDateRangeDialog: false,
      isShowHelpDialog: false,
      isShowEditDialog: false,
      isShowDeleteDialog: false,
      isCaseBulkDelete: false,
      isShowAdminReportDialog: false,
      isShowGroupReportHelpDialog: false,
      isShowCaseMarkDialog: false,
      reportDisplayerType: "",
      // report
      reportModeList: [
        {
          value: reportMode.admin.brief,
          text: "评估报告"
        },
        {
          value: reportMode.admin.detailed,
          text: "个人体检报告"
        }
      ],
      mmpiLbIdList: mmpiLbIdRange,
      mmpiReportModeList: [
        {
          value: reportMode.admin.brief,
          text: "社会适应性-简要报告"
        },
        {
          value: reportMode.admin.detailed,
          text: "社会适应性-详细报告"
        },
        {
          value: reportMode.admin.hosbrief,
          text: "精神病性-简要报告"
        },
        {
          value: reportMode.admin.hosdetailed,
          text: "精神病性-详细报告"
        }
      ],
      nuclearLbIdList: nuclearLbIdRange,
      nuclearReportModeList: [
        {
          value: reportMode.admin.detailed,
          text: "评估报告"
        },
        {
          value: reportMode.admin.brief,
          text: "个人体检报告"
        }
      ],
      selectedReportMode: "",
      reporterCandidateList: [],
      // actions
      actionCaseIndex: -1,
      actionCaseGuid: "",
      actionCaseReportId: "",
      actionCasePerson: {},
      editingCasePerson: {},
      casePersonNoUpdateFields: [],
      actionCaseObjList: [],
      newCaseMark: "",
      // rules
      fieldRules: {
        caseMark: [
          val => (val || "").length > 0 || "不能设置空标记",
          val => (val || "").length <= 20 || "标记不能超过20个字符"
        ]
      }
    };
  },

  computed: {
    ...mapGetters({
      userGuid: "user/userGuid",
      jwt: "user/jwt",
      roles: "user/roles",
      userEntity: "user/userEntity"
    }),
    isAdminRole() {
      return this.roles.includes("admin");
    },
    selectedCaseCountText() {
      if (this.caseListSelected && this.caseListSelected.length) {
        return `，  选中了${this.caseListSelected.length}个案例`;
      }
      return "";
    },
    caseDeleteDialogTitle() {
      if (this.isCaseBulkDelete) {
        return `确定要删除选中的 ${this.caseListSelected.length} 个案例吗？`;
      }
      return `确定要删除案例 ${this.actionCaseReportId} 吗？`;
    },
    deptColumnAlias() {
      let deptConfig = this.userFieldConfigList.filter(
        field => field.fieldName === "deptGuid"
      );
      if (deptConfig && deptConfig.length) {
        return deptConfig[0].fieldAlias;
      }
      return "";
    },
    allDeptNamesFromCaseList() {
      let depts = _.chain(this.caseList)
        .map(c => c.testPerson.deptName)
        .remove(dept => dept)
        .uniq()
        .value();
      depts.push("无");
      return depts;
    },
    allLbNamesFromCaseList() {
      return _.chain(this.caseList)
        .map(c => c.lbDispName)
        .remove(lb => lb)
        .uniq()
        .value();
    },
    filterDateRangeStart() {
      let dRange = this.caseFilters.dateRange;
      if (dRange.length === 1) {
        return dRange[0];
      } else if (dRange.length === 2) {
        return _.min(dRange);
      }
      return null;
    },
    filterDateRangeEnd() {
      let dRange = this.caseFilters.dateRange;
      if (dRange.length === 1) {
        return null;
      } else if (dRange.length === 2) {
        return _.max(dRange);
      }
      return null;
    },
    filterDateRangeText() {
      let dText = "";
      if (this.filterDateRangeStart) {
        dText += this.filterDateRangeStart;
      }
      if (this.filterDateRangeEnd) {
        dText += ` ~ ${this.filterDateRangeEnd}`;
      }
      return dText;
    },
    filterPersonFieldList() {
      let pFields = this.userFieldConfigList.filter(
        field =>
          field.fieldName !== "age" &&
          field.fieldName !== "deptGuid" &&
          field.isVisibleInTable
      );
      return pFields.map(field => field.fieldAlias);
    },
    filterPersonFieldName() {
      let matchedFieldConfig = this.userFieldConfigList.find(
        fc => fc.fieldAlias === this.personFilterFieldSelected
      );
      return matchedFieldConfig ? matchedFieldConfig.fieldName : "";
    }
  },

  watch: {
    isShowAdminReportDialog(newVal) {
      newVal || this.closeAdminReportDialog();
    }
  },

  methods: {
    buildTesteeNumPrefix(prefix) {
      return prefix ? `${prefix}-` : "";
    },
    resetCaseSortBy() {
      this.caseSortBy = ["testDate"];
    },
    resetCaseSortDesc() {
      this.caseSortDesc = [true];
    },
    // ============================ Fetch Data =================================
    async fetchCaseListData() {
      try {
        this.isCaseListLoading = true;
        this.caseList = await fetchCaseList(this.userEntity, this.userGuid, {
          lbNames: this.caseFilters.lbNames,
          departmentNames: this.caseFilters.deptNames.map(dName =>
            dName === "无" ? null : dName
          ),
          testDateStart: this.filterDateRangeStart,
          testDateEnd: this.filterDateRangeEnd,
          testPersonInfo: {
            field: this.filterPersonFieldName,
            value: this.personFilterValue
          }
        });
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isCaseListLoading = false;
    },
    async fetchUserFieldConfigList() {
      try {
        this.isCaseListLoading = true;
        this.userFieldConfigList = await getUserFieldConfig(
          this.userEntity,
          this.userGuid
        );
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isCaseListLoading = false;
    },
    async fetchReportSignConfig() {
      try {
        this.isCaseListLoading = true;
        var signInfo = await fetchReportSignInfo(
          this.userEntity,
          this.userGuid
        );
        this.reporterCandidateList = signInfo.reporterCandidates;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isCaseListLoading = false;
    },
    buildCaseListHeaders() {
      this.caseListHeaders = [{ text: "编号", value: "reportId" }];
      for (let fieldConfig of this.userFieldConfigList) {
        // 对 deptGuid 来说，直接使用 deptName 是最高效的，可以不用再次获取 deptSelectList
        let fieldName =
          fieldConfig.fieldName === "deptGuid"
            ? "deptName"
            : fieldConfig.fieldName;
        if (fieldConfig.isVisibleInTable) {
          if (fieldConfig.fieldName === "deptGuid") {
            this.caseListHeaders.push({
              text: fieldConfig.fieldAlias,
              value: `testPerson.${fieldName}`
            });
          } else {
            this.caseListHeaders.push({
              text: fieldConfig.fieldAlias,
              value: `testPerson.${fieldName}`
            });
          }
        }
      }
      this.caseListHeaders.push({
        text: "量表",
        value: "lbDispName"
      });
      this.caseListHeaders.push({
        text: "测量日期",
        value: "testDate",
        sort: (d1, d2) => new Date(d1) - new Date(d2)
      });
      this.caseListHeaders.push({ text: "标记", value: "caseMark" });
      this.caseListHeaders.push({
        text: "预警",
        value: "alertList",
        sort: (a1, a2) => a1.length - a2.length
      });
      this.caseListHeaders.push({
        text: "操作",
        value: "actions",
        sortable: false
      });
    },
    // ============================ Case Filters =================================
    async filterCaseList() {
      // 筛选前清空选择
      this.caseListSelected = [];
      await this.fetchCaseListData();
    },
    async resetCaseFilters() {
      // 筛选前清空选择
      this.caseListSelected = [];
      this.caseFilters = {
        lbNames: [],
        deptNames: [],
        dateRange: []
      };
      this.resetPersonFilterFieldSelected();
      this.personFilterValue = "";
      await this.fetchCaseListData();
    },
    resetPersonFilterFieldSelected() {
      if (this.filterPersonFieldList && this.filterPersonFieldList.length) {
        this.personFilterFieldSelected = this.filterPersonFieldList[0];
      }
    },
    // ============================ Case Actions - Select All =================================
    caseToggleSelectAll({ value }) {
      if (value) {
        this.caseListSelected = this.caseList;
      } else {
        this.caseListSelected = [];
      }
    },
    // ============================ Case Actions - Case Mark =================================
    async singleSetCaseMark(caseGuid, newMark) {
      await this.updateCaseMark([caseGuid], newMark);
    },
    bulkSetCaseMark() {
      if (this.caseListSelected.length) {
        this.isShowCaseMarkDialog = true;
      }
    },
    caseMarkDialogClosed() {
      this.newCaseMark = "";
    },
    async bulkSetCaseMarkConfirmed() {
      let updateCaseGuids = this.caseListSelected.map(c => c.guid);
      await this.updateCaseMark(updateCaseGuids, this.newCaseMark);
      // 更新显示的列表
      this.caseList.map(c => {
        if (updateCaseGuids.includes(c.guid)) {
          c.caseMark = this.newCaseMark;
        }
        return c;
      });
      this.isShowCaseMarkDialog = false;
    },
    async updateCaseMark(caseList, newMark) {
      try {
        this.isLoading = true;
        await saveCaseMark(caseList, newMark);
      } catch (err) {
        this.errorMsg = err;
      }
      this.isLoading = false;
    },
    // ============================ Case - Delete =================================
    deleteCases(isBulkDelete, itemToDelete = null) {
      this.isCaseBulkDelete = isBulkDelete;
      if (isBulkDelete && this.caseListSelected.length) {
        this.isShowDeleteDialog = true;
      } else if (!isBulkDelete && itemToDelete) {
        this.assignActionCase(itemToDelete);
        this.isShowDeleteDialog = true;
      }
    },
    async deleteCasesConfirmed() {
      try {
        this.isBtnLoading = true;
        let caseGuidsToDelete = [];
        if (this.isCaseBulkDelete && this.caseListSelected.length) {
          caseGuidsToDelete = this.caseListSelected.map(c => c.guid);
        } else if (!this.isCaseBulkDelete && this.actionCaseGuid) {
          caseGuidsToDelete = [this.actionCaseGuid];
        }
        let caseGuidsDeleted = await deleteCases(caseGuidsToDelete);
        // 更新显示的列表
        if (caseGuidsDeleted && caseGuidsDeleted.length) {
          this.caseList = this.caseList.filter(
            c => !caseGuidsDeleted.includes(c.guid)
          );
        }
        this.isShowDeleteDialog = false;
      } catch (err) {
        this.errorMsg = err;
      }
      this.isBtnLoading = false;
    },
    caseDeleteDialogClosed() {
      // 重置actionCase
      this.assignActionCase(null);
    },
    // ============================ Case Item Actions - Edit =================================
    assignActionCase(caseItem) {
      this.actionCaseIndex = caseItem ? this.caseList.indexOf(caseItem) : -1;
      this.actionCaseGuid = caseItem ? caseItem.guid : "";
      this.actionCasePerson = caseItem ? caseItem.testPerson : {};
      this.actionCaseReportId = caseItem ? caseItem.reportId : "";
    },
    editOneCase(item) {
      this.assignActionCase(item);
      this.editingCasePerson = { ...item.testPerson };
      this.isShowEditDialog = true;
    },
    async editOneCaseConfirmed() {
      try {
        this.isBtnLoading = true;
        await saveEditedPerson(
          { ...this.editingCasePerson },
          this.casePersonNoUpdateFields
        );
        // 更新显示的列表
        let casesToUpdate = this.caseList.filter(
          c => c.testPerson.guid === this.editingCasePerson.guid
        );
        for (let c of casesToUpdate) {
          Object.assign(c, { testPerson: this.editingCasePerson });
        }
        this.isShowEditDialog = false;
      } catch (err) {
        this.errorMsg = err;
      }
      this.isBtnLoading = false;
    },
    caseEditDialogClosed() {
      // 重置actionCase
      this.assignActionCase(null);
      this.editingCasePerson = {};
    },
    // ============================ Case Item Actions - Report =================================
    showAdminReport(item, reportMode) {
      this.assignActionCase(item);
      this.reportDisplayerType = "admin";
      this.selectedReportMode = reportMode;
      this.isShowAdminReportDialog = true;
    },
    showGroupReport() {
      if (this.caseListSelected.length) {
        this.reportDisplayerType = "group";
        this.actionCaseObjList = [...this.caseListSelected];
        this.isShowAdminReportDialog = true;
      }
    },
    showAnswerDetails(item) {
      this.assignActionCase(item);
      this.reportDisplayerType = "answer";
      this.isShowAdminReportDialog = true;
    },
    closeAdminReportDialog() {
      this.isShowAdminReportDialog = false;
      this.$nextTick(() => {
        this.assignActionCase(null);
        this.reportDisplayerType = "";
        this.actionCaseObjList = [];
      });
    },
    // ============================ 批量下载 pdf (zip/pdf) =================================
    async bulkDownloadAsZip(reportMode) {
      if (this.caseListSelected.length) {
        try {
          this.isLoading = true;
          let reportExportList = this.caseListSelected.map(c => {
            return {
              caseGuid: c.guid,
              reportModeName: reportMode
            };
          });
          let downloadPath = await bulkSaveReportToZip(reportExportList);
          downloadFile(downloadPath, "批量报告.zip");
        } catch (err) {
          this.errorMsg = err;
        }
        this.isLoading = false;
      }
    },
    async bulkDownloadAsPdf(reportMode) {
      if (this.caseListSelected.length) {
        try {
          this.isLoading = true;
          let reportExportList = this.caseListSelected.map(c => {
            return {
              customerGuid: this.userGuid,
              caseGuid: c.guid,
              reportModeName: reportMode
            };
          });
          let downloadPath = await bulkSaveReportToSinglePdf(reportExportList);
          downloadFile(downloadPath, "批量合并报告.pdf");
        } catch (err) {
          this.errorMsg = err;
        }
        this.isLoading = false;
      }
    },
    // ============================ 导出 Excel =================================
    async extractCasesToExcel() {
      if (this.caseListSelected.length) {
        try {
          this.isLoading = true;
          let caseLbName = "";
          // 必须为同一个量表
          let caseLbNameSet = new Set(
            this.caseListSelected.map(c => c.lbDispName)
          );
          if (caseLbNameSet.size > 1) {
            this.errorMsg = "只能导出同一个量表的案例";
          } else {
            caseLbName = this.caseListSelected[0].lbDispName;
            let caseGuidList = this.caseListSelected.map(c => c.guid);
            let downloadPath = await extractExcelFromCases(
              caseLbName,
              caseGuidList,
              false
            );
            downloadFile(downloadPath, `${caseLbName}.xlsx`);
          }
        } catch (err) {
          this.errorMsg = err;
        }
        this.isLoading = false;
      }
    }
  },

  async created() {
    await this.fetchUserFieldConfigList();
    this.buildCaseListHeaders();
    await this.fetchCaseListData();
    this.fetchReportSignConfig();
    // 初始化filter
    this.resetPersonFilterFieldSelected();
    // 初始化sort，不能直接写在template中，任何操作都会跳转回默认排序
    this.resetCaseSortBy();
    this.resetCaseSortDesc();
  }
};
</script>

<style lang="scss" scoped>
$text-field-outlined-prepend-append-margin-top: 0px;

.filter-item {
  max-width: 400px;
  min-width: 200px;
}
.filter-item-person {
  padding-left: 0px;
}
.filter-item-inner-select {
  max-width: 100px;
  min-width: 50px;
  margin: -8px 12px 1px -12px;
}
</style>
